import React from "react"
import { AuthProvider } from "setup/auth"

import { AppInsightsProvider } from "setup/app-insights"
import { IntlProvider } from "setup/intl"
import { ModalModule } from "setup/modal/ModalModule"
import { StyledProvider } from "setup/styled"
import { Auth } from "../api/Provider"
import { api } from "../api/api"

export const AppProviders: React.FC = ({ children }) => (
  <AppInsightsProvider>
    <IntlProvider>
      <StyledProvider>
        <ModalModule>
          <AuthProvider>
            <Auth api={api}>{children}</Auth>
          </AuthProvider>
        </ModalModule>
      </StyledProvider>
    </IntlProvider>
  </AppInsightsProvider>
)
