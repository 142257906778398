import get from "lodash/get"
import { Nullable } from "tsdef"
import { FormatDateOptions } from "react-intl"
import formats from "setup/intl/formats"
import { intl } from "setup/intl"
import { messages } from "setup/messages/messages"

export const formatDate = (
  date: Nullable<string | Date>,
  options?: FormatDateOptions
) => (date ? intl.formatDate(date, options) : "")

export const formatTime = (
  date: Nullable<string | Date>,
  options?: FormatDateOptions
) => (date ? intl.formatTime(date, options) : "")

export const formatDateTime = (
  date: Nullable<string | Date>,
  options?: FormatDateOptions
) =>
  date
    ? messages.generic.datetime.format(
        intl.formatDate(date, options),
        intl.formatTime(date, options)
      )
    : ""

export const getLocaleDatePattern = (): string =>
  get(formats, intl.locale, "dd/MM/yyyy")

export const getDatePatternMask = (format: string) =>
  format.split("").map((char) => (/[a-zA-Z]/.test(char) ? /\d/ : char))
