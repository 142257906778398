import React from "react"
import { Flex, H1, H4 } from "@ikiru/talentis-fpc"
import { useClientPortalConfiguration } from "../../module/context"

const Assignment = () => {
  const { assignment } = useClientPortalConfiguration()

  return (
    <Flex
      p="m"
      width="100%"
      bg="grey.lightest"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      style={{
        boxSizing: "border-box"
      }}
    >
      <H1 mt="none" mb="xxs" color="grey.dark" fontWeight={600}>
        {assignment?.name}
      </H1>
      <H4 my="xxs" color="grey.darkest" fontWeight={500}>
        {assignment?.companyName}
      </H4>
      <H4 my="none" color="grey.darkest" fontWeight={500}>
        {assignment?.location}
      </H4>
    </Flex>
  )
}

export default React.memo(Assignment)
