import get from "lodash/get"
import {
  Div,
  getMinWidthMediaQuery,
  H3,
  H4,
  Section,
  Toggle
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { InterviewProgressStage } from "../../constants/interview-stages-order"
import { useStageCandidates } from "./hooks"
import { useMediaQuery } from "utils/hooks/use-media-query"
import CandidateRecord from "../CandidateRecord"

type StageCandidatesSectionProps = {
  stage: InterviewProgressStage
  key: any
}

const StageCandidatesSection = ({
  stage,
  key
}: StageCandidatesSectionProps) => {
  const {
    photos,
    candidates,
    selectedCandidatesCount,
    areAllSelected,
    onToggleStage,
    onToggleCandidate
  } = useStageCandidates(stage)

  const mediaQuery = getMinWidthMediaQuery("sm")
  const isMobile = !useMediaQuery(mediaQuery)
  const isCandidatesExist = Boolean(candidates.length)

  return (
    <Div width="100%">
      <Section
        key={key}
        //@ts-ignore
        expandable
        header={{
          // @ts-ignore
          size: "small",
          title: get(messages, `assignment.interviewStage.${stage}`),
          itemsCount: candidates.length,
          titleProps: {
            fontWeight: 500,
            as: H4,
            my: 0,
            pt: 0
          }
        }}
        bodyProps={{ py: "xs", pb: isCandidatesExist ? "0" : "xs" }}
        mt={5}
        mb="xs"
      >
        {candidates.map((candidate: any, index: number) => (
          <Div key={index} mt="xs" width="100%">
            <CandidateRecord
              {...{ candidate }}
              photo={photos?.[candidate.id]}
              onToggle={onToggleCandidate(candidate)}
            />
          </Div>
        ))}
      </Section>
    </Div>
  )
}

export default StageCandidatesSection
