import { Button, Flex, H1 } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { ErrorMessage } from "./style"
import { useHistory } from "react-router-dom"

const NoPageFound = () => {
  const history = useHistory()
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-around"
      alignItems="center"
      mt="xxl"
    >
      <H1 color="green.standard" fontSize={200}>
        {messages.pageNotFound.fourZeroFour}
      </H1>
      <ErrorMessage>{messages.pageNotFound.pageNotFound}</ErrorMessage>
      <Button onClick={() => history.push("/")}>
        {messages.pageNotFound.goHome}
      </Button>
    </Flex>
  )
}

export default NoPageFound
