import { Endpoints } from "setup/api/api.types"

export enum Directories {
  Assignments = "Assignments"
}

export enum AssignmentsEndpoints {
  SharedAssignments = "SharedAssignments",
  AssignmentDetail = "AssignmentDetail"
}

export enum UsersEndpoints {
  Me = "Me"
}

export const endpoints: Endpoints = {
  [AssignmentsEndpoints.AssignmentDetail]: (id) =>
    `portal/${Directories.Assignments}/${id}`,
  [AssignmentsEndpoints.SharedAssignments]: () =>
    `/portal/${Directories.Assignments}/SharedAssignments`,
  [UsersEndpoints.Me]: () => `/portal/Me`
}
