import { RouterUrl } from "setup/router/routes"
import { OIDCRedirectRouteAction } from "./enums"
import { getRouteWithPageUrl } from "./utils"

export const OIDCSignInRedirectRouteUrl =
  `/oidc-redirect/${OIDCRedirectRouteAction.SignIn}` as RouterUrl
export const OIDCSignOutRedirectRouteUrl =
  `/oidc-redirect/${OIDCRedirectRouteAction.SignOut}` as RouterUrl

export const mainRoute = getRouteWithPageUrl(RouterUrl.Homepage)
export const OIDCServerUrl =
  process.env.REACT_APP_OIDC_URL || window.fpConfig.identityServerUrl
