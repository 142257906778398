import React, { useCallback, useReducer, useRef } from "react"
import { ModalContext } from "setup/modal/modal.context"
import { modalReducer, initialModalState } from "setup/modal/modal.reducer"
import { ModalNames } from "setup/modal/modal.definitions"
import {
  ModalActionTypes,
  ModalCallback,
  ModalCallbackCollection,
  OpenModal,
  CloseModal,
  CloseAllModals
} from "setup/modal/modal.types"
import { tempWrapperStyles } from "setup/modal/modal.helpers"

type ModalModuleProps = {
  children: React.ReactNode
}

export const ModalModule = (props: ModalModuleProps) => {
  const { children } = props
  const [state, dispatch] = useReducer(modalReducer, initialModalState)
  const onCloseCallbacks = useRef<ModalCallbackCollection>([])

  const callCallbacks = useCallback(
    (collection: ModalCallbackCollection, name?: string) => {
      const callbacks = name
        ? collection.filter((entry) => entry.name === name)
        : collection
      for (const { callback } of callbacks) {
        callback()
      }
    },
    []
  )

  const open: OpenModal = useCallback(
    (name, modal) => {
      dispatch({
        type: ModalActionTypes.Open,
        payload: { name, element: modal }
      })
    },
    [dispatch]
  )

  const close: CloseModal = useCallback(
    (name) => {
      callCallbacks(onCloseCallbacks.current, name)
      dispatch({ type: ModalActionTypes.Close, payload: { name } })
    },
    [callCallbacks, dispatch]
  )

  const closeAll: CloseAllModals = useCallback(() => {
    callCallbacks(onCloseCallbacks.current)
    dispatch({ type: ModalActionTypes.CloseAll })
  }, [callCallbacks, dispatch])

  const onClose = useCallback(
    (name: ModalNames, callback: ModalCallback) => {
      onCloseCallbacks.current.push({ name, callback })
    },
    [onCloseCallbacks]
  )

  const removeOnCloseCallback = useCallback(
    (name: string, callback: ModalCallback) => {
      onCloseCallbacks.current = onCloseCallbacks.current.filter(
        (entry) => entry.name !== name && entry.callback !== callback
      )
    },
    []
  )

  return (
    <ModalContext.Provider
      value={{
        activeModal: state.activeModal,
        isOpen: state.isOpen,
        openModals: state.openModals,
        open,
        close,
        closeAll,
        onClose,
        removeOnCloseCallback
      }}
    >
      {children}
      {state.isOpen && (
        <div
          onClick={closeAll}
          style={tempWrapperStyles}
          data-e2e-target="modal-wrapper"
        >
          <div onClick={(e) => e.stopPropagation()}>
            {Object.entries(state.openModals)
              .filter(([, value]) => value.isOpen)
              .map(([key, value]) =>
                React.cloneElement(value.element, { key })
              )}
          </div>
        </div>
      )}
    </ModalContext.Provider>
  )
}
