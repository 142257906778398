import { Div, Flex, H2, Loader, Section, LeftArrow } from "@ikiru/talentis-fpc"
import Assignment from "./components/Assignment"
import StageCandidatesSection from "./components/StageCandidatesSection"
import { messages } from "setup/messages/messages"
import {
  InterviewProgressStage,
  interviewStagesOrder
} from "./constants/interview-stages-order"
import { useClientPortalConfiguration } from "./module/context"
import { RouterLink } from "components/RouterLink/RouterLink"
import { RouterUrl } from "setup/router/routes"
import { CandidateType } from "./module/types"

const AssignmentDetail = () => {
  const { isCandidatesLoading, candidates } = useClientPortalConfiguration()

  const getActiveGroups = () => {
    const activeAssignments = candidates.map(
      (candidate: CandidateType) => candidate?.interviewProgressState?.stage
    )
    const groupsToShow = interviewStagesOrder.filter(
      (group: InterviewProgressStage) => activeAssignments.includes(group)
    )
    return groupsToShow
  }

  return (
    <>
      <RouterLink to={RouterUrl.Homepage} fontWeight={600}>
        <LeftArrow height="11" />
        {messages.clientPortal.goBack}
      </RouterLink>

      <Flex style={{ overflowY: "hidden", flex: 1 }} flexDirection="column">
        <Flex
          style={{ overflowY: "auto" }}
          flexDirection="column"
          px={["s", "s", "l"]}
          py={["s", "s", "l"]}
        >
          <Div mb="m">
            <Assignment />
          </Div>
          <Section
            header={{
              title: messages.clientPortal.candidates,
              itemsCount: candidates.length,
              titleProps: {
                as: H2,
                my: 0,
                pt: 0
              }
            }}
            //@ts-ignore
            expandable
            bodyProps={{
              px: "none",
              pt: "xxs",
              pb: "none"
            }}
          >
            {isCandidatesLoading ? (
              <Loader />
            ) : (
              getActiveGroups().map((stage) => (
                <StageCandidatesSection key={stage} {...{ stage }} />
              ))
            )}
          </Section>
        </Flex>
      </Flex>
    </>
  )
}

export default AssignmentDetail
