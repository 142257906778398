import { Switch, BrowserRouter } from "react-router-dom"
import NoPageFound from "views/nopagefound/NoPageFound"

export const CustomSwitch = (props: any) => {
  const { children } = props
  return (
    <BrowserRouter>
      <Switch>
        {children}
        <NoPageFound />
      </Switch>
    </BrowserRouter>
  )
}
