export default {
  form: {
    generic: {
      name: "Name",
      fullName: "Full name",
      jobTitle: "Job title",
      company: "Company",
      location: "Location",
      role: "Role",
      emailAddresses: "Email addresses",
      emailAddress: "Email address",
      phone: "Phone number",
      webSite: "Website",
      webSites: "Websites",
      linkedInUrl: "LinkedIn URL",
      submit: "Submit",
      signup: "Sign up",
      companyName: "Company name",
      country: "Country",
      firstName: "First name",
      lastName: "Last name",
      phoneNumber: "Phone number",
      phoneNumbers: "Phone numbers",
      password: "Password",
      enterAPearsonsName: "Enter a person's name...",
      searchContacts: "Search contacts",
      browse: "Browse",
      clear: "Clear",
      upload: "Upload",
      errors: {
        mustBeNoLongerThan: "Must be no longer than {0} characters.",
        urlFormat: "Wrong URL format",
        captcha:
          "This appears to be an automated request. If this is incorrect, please contact us!",
        invalidEmail: "Invalid email address",
        emailExists: "Email already exists"
      },
      save: "Save",
      assignmentName: "Assignment name",
      startDate: "Start date",
      today: "Today",
      assignment: "Assignment",
      assignmentStartDate: "Assignment start date",
      assignmentStatus: "Assignment status",
      sector: "Sector",
      keywords: "Keywords",
      workHistory: "Work history",
      show: "Show",
      hide: "Hide",
      removeReminder: "Remove reminder",
      assignTo: "Assign to",
      addYourFirstAssignment: "Add your first assignment",
      useAnAssignment:
        "Use an assignment to build a list of suitable candidates, track their progress and share with your clients and colleagues"
    }
  },
  person: {
    title: "People",
    details: {
      name: "Name",
      jobTitle: "Job title",
      company: "Company",
      location: "Location",
      linkedInUrl: "LinkedIn URL",
      errors: {
        invalidLink: "Please enter a valid link.",
        invalidEmail: "Invalid email address"
      }
    },
    assignments: {
      title: "Assignments",
      noAssignmentsMessage:
        "You need to create an assignment before you can attach a person to it",
      assignmentLink: "Assignment link",
      linkToAssignment: "Link to assignment",
      selectAssignment: "Select assignment",
      interviewProgressTitle: "Status",
      interviewProgress: {
        stages: {
          identified: "Identified",
          screening: "Screening",
          internalInterview: "Internal interview",
          shortList: "Shortlist",
          firstClientInterview: "First client interview",
          secondClientInterview: "Second client interview",
          thirdClientInterview: "Third client interview",
          offer: "Offer",
          placed: "Placed",
          archive: "Archive"
        },
        statuses: {
          noStatus: "[No status]",
          needToContact: "Need to contact",
          leftMessage: "Left message",
          contacted: "Contacted",
          interested: "Interested",
          notInterested: "Not interested",
          arrangingInterview: "Arranging interview",
          interviewPlanned: "Interview planned",
          awaitingFeedback: "Awaiting feedback",
          awaitingReferences: "Awaiting references",
          onHold: "On hold",
          withdrew: "Withdrew",
          rejected: "Rejected"
        }
      },
      dueDate: "Due date",
      none: "[None]"
    },
    experience: {
      title: "Professional experience",
      form: {
        sectorLabel: "Sector"
      }
    },
    keywords: {
      title: "Keywords",
      form: {
        addKeywordPlaceholder: "Add keyword",
        addKeywordLengthError:
          "The keyword should be at most {0} characters long."
      }
    },
    consents: {
      title: "Person's preferences",
      dataPrivacy: "Data privacy",
      form: {
        lawfulBasisLabel: "Lawful basis",
        statusLabel: "Privacy status",
        dataOriginLabel: "Data origin",
        dataOriginError: "Data origin must be at most {0} characters long.",
        options: {
          basis: {
            notSet: "Not set",
            none: "[none]",
            verbalConsent: "Verbal consent",
            emailConsent: "Email consent",
            digitalConsent: "Digital consent",
            legitimateInterest: "Legitimate interest",
            notRequired: "Not required"
          },
          status: {
            notStarted: "Not started",
            consentRequestSent: "Consent request sent",
            consentGiven: "Consent given",
            consentRefused: "Consent refused",
            notificationSent: "Notification sent",
            objected: "Objected"
          }
        }
      }
    },
    uploadDocument: {
      fileAdded: "File added",
      title: "Documents",
      newDocument: "Document"
    },
    avatar: {
      avatar: "Avatar",
      uploadAvatar: "Add a photo",
      uploadCta: "Choose a file",
      photoUploadingPleaseWait: "Photo uploading, please wait",
      savePhoto: "Save photo",
      deletePhoto: "Delete photo",
      changePhoto: "Change photo",
      fileSizeMust: "File size must be no larger than 2MB",
      fileFormatMust: "File format must be a jpg or gif or png or jpeg",
      confirmDeletionMessage: "Are you sure you want to delete the photo?"
    },
    note: {
      title: "Notes",
      addNote: "Add note",
      newNote: "Note",
      deleteNote: "Delete note",
      save: "Save",
      cancel: "Cancel",
      edit: "Edit",
      form: {
        noteTitle: "Note title",
        noteDescription: "Note description",
        options: {
          none: "[none]"
        }
      },
      showLessNote: "show less note",
      showFullNote: "show full note",
      edited: "Edited"
    },
    teasers: {
      found: "Found",
      and: "and",
      emails: "emails",
      phoneNumbers: "phone numbers",
      contactDetailsNotFound: "No contact details found",
      fetchingContactDetails: "Fetching contact...",
      getContactDetails: "Get contact details",
      unableToRetrieve: "Unable to retrieve contact, please try again later.",
      outOfCredits: "You do not have enough credits for this action.",
      noContactDetails: "No contact details found. No credits were used."
    },
    newContact: {
      potentialDuplicatesFound: "{0} potential duplicates found",
      saveNewRecordQuestion: "Are you sure you want to save a new record?",
      saveNewRecord: "Yes, save new record",
      personAddedToCompanyData:
        "This person will only be added to your company's data, not the Talentis Talent Graph.",
      notAutoUpdates: "This person's details will not automatically update.",
      addContact: "Add contact",
      createNewContact: "Create new contact"
    }
  },
  createPerson: {
    title: "Add a new person",
    warning: "This person may already exist",
    viewPerson: "View Person",
    submitCta: "Add person"
  },
  searchPerson: {
    title: "Contacts",
    addContact: "Contact",
    tableColumns: {
      personsName: "Person's name",
      jobTitle: "Job title",
      company: "Company",
      location: "Location",
      createdData: "Created date",
      linkedInUrl: "LinkedIn URL",
      url: "Url"
    },
    addPersonModal: {
      header:
        "Find a person's online profile to import their information and start adding to their record",
      subHeader:
        "After importing a person to the TalentGraph their information will be automatically updated each time their profile is viewed.",
      submitCta: "Search for person"
    }
  },
  manageCompany: {
    title: "Settings",
    team: "Team",
    invite: "Invite",
    inviteUser: "Invite user",
    sendInvite: "Send Invite",
    inviteUserCta: "Generate invitation link",
    finishRegistration: "Finish the registration process",
    inviteAnother: "Invite another user",
    success: {
      title: "Invitation created",
      message: "The invitation email has been sent to"
    },
    invited: "Invited",
    currentTeam: "Current team",
    rolePermissions: "Role permissions",
    roles: {
      owner: "Owner",
      ownerPermissions: {
        hasFullAccess: "Has full access."
      },
      admin: "Admin",
      adminPermissions: {
        canEditTeamSettings: "Can edit team settings.",
        cannotAssignOwnerRole: "Cannot assign Owner role.",
        cannotAccessPlanAndBillingSettings:
          "Cannot access plan and billing settings."
      },
      teamMember: "Member",
      teamMemberPermissions: {
        cannotAccess: "Cannot access plan, billing or team settings."
      }
    },
    revokeModal: {
      title: "Are you sure you want to remove this invite",
      cancelLabel: "No don't remove",
      okLabel: "Yes, remove invite"
    },
    resendInvite: "Resend invite",
    removeInvite: "Remove invite",
    inviteSent: "Invite sent",
    inviteResent: "Invite resent",
    makeActive: "Make active",
    makeInactive: "Make inactive",
    inactive: "Inactive",
    makeUserInactiveQuestion:
      "Are you sure you want to make this user inactive?",
    makeUserActiveQuestion: "Are you sure you want to make this user active?",
    inactiveUsersCannotLogIn:
      "Inactive users cannot log in or use the browser extension",
    yesMakeInactive: "Yes, make inactive",
    dontMakeInactive: "No, don't make inactive",
    yesMakeActive: "Yes, make active",
    dontMakeActive: "No, don't make active",
    licensesModal: {
      allOfYourUser: "All of your user licenses have been allocated",
      toInviteSomeone:
        "To invite someone else you'll need to increase the number of users on your plan, remove an invited user or make a user inactive.",
      contactUs: "Contact us to purchase user licenses",
      backToTeam: "Back to team page"
    }
  },
  registerUser: {
    invalid: {
      base: "Invalid invite link"
    },
    complete: {
      accountCreated: "Your account has been successfully created"
    }
  },
  verifySearchFirm: {
    loading: "We're verifying your email address... "
  },
  signup: {
    signupForTalentis: "Sign up for your {freetrial} of Talentis",
    freeTrial: "free trial",
    noCreditCardRequired: "No credit card required.",
    alreadyGotAnAccount: "Already got an account",
    logIn: "Log In",
    verifyYourEmail:
      "Verify your email address to complete the sign up process",
    emailSentTo: "The verification email has been sent to ",
    accountHasNotBeenVerified: "Account has not been verified.",
    emailAddressHasAlreadyBeenInvitedToATeam:
      "Email address has already been invited to a team.",
    resendVerificationEmail: "Resend verification email",
    resendInvitationEmail: "Resend me my invitation email",
    invitationEmailHasBeenSent: "Invitation email has been sent.",
    verificationEmailHasBeenSent: "Verification email has been sent.",
    extension: {
      getReadyToUse: "Get ready to use Talentis...",
      installBrowserExtensionDescription:
        "You'll need to install the browser extension to use most of the features in Talentis...",
      installBrowserExtension: "Install the browser extension"
    },
    bySigningYouAgree: "By signing up you agree to our",
    termsOfUse: "terms of use",
    and: "and",
    privacyPolicy: "privacy policy"
  },
  search: {
    noContactsFound: "No contacts found",
    toCreateContacts:
      "To create contacts use the Talentis Chrome extension while viewing an online profile and add information to the person's record.",
    useTheSearch:
      " Use the search bar at the top of the page or visit a person's online profile to get started.",
    searchedPhrase: "Searched phrase",
    totalResults: "Total results"
  },
  auth: {
    login: "Log in",
    logout: "Log out",
    signup: "Sign Up"
  },
  navigation: {
    links: {
      homepage: "Home",
      managePersons: "Manage People",
      addPerson: "Add Person",
      searchPerson: "Search Person",
      manageCompany: "Settings",
      addAssignment: "Assignment",
      listAssignment: "Assignments",
      listContacts: "Contacts",
      search: "Search"
    },
    actions: {
      settings: "Settings",
      profile: "Account",
      nn: "No Name",
      billing: "Billing"
    },
    helpers: {
      help: "Help",
      feedback: "Feedback",
      upgrade: "Upgrade plan"
    }
  },
  assignment: {
    title: "Manage Assignments",
    plural: "Assignments",
    addNew: "Assignment",
    createAssignment: "Create Assignment",
    addAnAssignment: "Add an assignment",
    editAssignment: "Edit Assignment",
    roleDetails: "Role details",
    clientDetails: "Client details",
    interviewStage: {
      identified: "Identified",
      screening: "Screening",
      internalInterview: "Internal interview",
      shortList: "Shortlist",
      firstClientInterview: "First client interview",
      secondClientInterview: "Second client interview",
      thirdClientInterview: "Third client interview",
      offer: "Offer",
      placed: "Placed",
      archive: "Archive"
    },
    details: {
      title: "Assignment details",
      started: "Assignment started",
      candidates: {
        title: "Candidates",
        filteredCount: "{0} of {1}",
        noCandidates: "No Candidates.",
        export: "Export",
        exporting: "Exporting...",
        errorOccured: "Unable to download file. Please try again.",
        linkPeople:
          "Link people to this assignment to view them here and track their progress",
        useChromeExtension:
          "Using the Talentis Chrome extension is the easiest way to link people to assignments.",
        useSearchBar:
          "Use the search bar at the top of the page or visit a person's online profile to get started.",
        updating: "Updating",
        exportPrefix: "candidates",
        searchForCandidates: "Search for candidates",
        addCandidateWhileBrowsingProfileOnline:
          "You can also add candidates to your assignment while viewing their online profile",
        howToLink: "How to link candidates to assignments"
      }
    },
    listAssignment: "Assignments",
    tableColumns: {
      name: "Name",
      companyName: "Company",
      jobTitle: "Job title",
      location: "Location",
      startDate: "Start date",
      status: "Status"
    },
    statuses: {
      active: "Active",
      onHold: "On hold",
      abandoned: "Abandoned",
      placed: "Placed"
    },
    similarPeople: {
      findSimilarPeople: "Find similar people using AI",
      findingSimilarPeople: "Finding similar people",
      selectCandidateToMatch: "Select a candidate to match from",
      notFound: "No similar people found",
      selectDifferentCandidate: "Select a different candidate to match from",
      selected: "{0} selected",
      selectAll: "Select all",
      moveSelected: "Move selected",
      stage: "Stage",
      addCandidates: "Add candidates to start matching",
      updatingStageFor: "Updating stage for",
      movedTo: "moved to",
      error: "An error occurred when moving {0} to {1}. Please try again.",
      seeSimilarPeople: "See similar people"
    },
    roleAtCompany: "{0} at {1}",
    filters: {
      filter: "Filter",
      status: "Status",
      assignedTo: "Assigned to",
      dueDate: "Due date",
      dueDateOptions: {
        overdue: "Overdue",
        last7Days: "In last 7 days",
        yesterday: "Yesterday",
        today: "Today",
        tomorrow: "Tomorrow",
        next7Days: "In next 7 days",
        next14Days: "In next 14 days",
        next30Days: "In next 30 days",
        anyDate: "Any date",
        noDueDate: "No due date"
      }
    }
  },
  generic: {
    loading: "Content loading, please wait",
    returnToHomepage: "Return to homepage",
    unexpectedError:
      "An unexpected error occured. Try refreshing the page. If the error persists, please contact us!",
    save: "Save",
    submit: "Submit",
    cancel: "Cancel",
    edit: "Edit",
    required: "Required",
    add: "Add",
    close: "Close",
    datetime: "{0} at {1}",
    remove: "Remove",
    errorTryAgain: "Something went wrong. Please try again.",
    complete: "Complete",
    generalError: {
      title: "An error occurred",
      subTitle: "Please refresh the page to try again",
      text: "If the problem persist please "
    },
    contactSupport: "Contact Support",
    update: "Update"
  },
  googleSearch: {
    label: "Find online profiles...",
    people: "People",
    companies: "Companies",
    searchOnGoogle: "Search the web"
  },
  browserExtension: {
    install: "Install the browser extension",
    findAndEngageWithCandidates:
      "find and engage with potential candidates across the web",
    worksBestInChrome:
      "Talentis works best in Chrome - use the browser extension and full site for the optimum experience."
  },
  subscription: {
    faqs: "FAQs",
    loadingPlans: "Updating prices",
    faq: {
      title: "FAQs",
      items: {
        upgradePlan: {
          question: "Can I upgrade to Connect later?",
          answer: "Yes you can upgrade at any time."
        },
        changeTeamSize: {
          question: "Can I change the team size later?",
          answer:
            "Yes, you can change the team size at any time. You'll be charged a pro-rated fee for adding new users. Any reduction in users will take effect at the end of your billing period."
        },
        paymentMethods: {
          question: "What payment methods do you accept?",
          answer: "We accept all major debit and credit cards in every country."
        },
        contractLength: {
          question: "How long is the contract?",
          answer: "You can choose from monthly or yearly contracts."
        }
      }
    },
    purchase: {
      upgradeYourPlan: "Upgrade your plan",
      freeTrialEnds: "Your free trial ends {0}",
      selectYourPlan: "Select your plan",
      selectPlan: "Select plan",
      planDetails: "Plan details",
      payByCard: "Pay by card",
      confirmPlan: "Confirm Plan",
      normalPrice: "Normal price {0}",
      choosePlan: {
        basic: "Choose Talentis",
        connect: "Choose Talentis Connect"
      },
      legalSubtext:
        "Prices are based on a yearly contract. Prices exclude any applicable taxes, these will be shown on the payment page. Plans are paid in advance. Plans automatically renew at standard pricing."
    },
    planDetails: {
      form: {
        totalNumberOfUsers: "Total number of users",
        currentNumberOfUsers: "current number of users",
        currency: "Currency",
        billingFrequency: "Billing frequency",
        yearlyAmount: "Yearly - {0}",
        monthlyAmount: "Monthly - {0}",
        perUserPerMonth: "per user, per month",
        wasAmount: "Was {0}",
        continueToBillingInformation: "Continue to billing information",
        continueToPayment: "Continue to payment",
        forMoreThan: "For more than 50 users please",
        contactUs: "contact us",
        soWeCanTailor: "so we can tailor the plan to your needs."
      },
      legalSubtext:
        "Prices exclude any applicable taxes, these will be shown on the payment page. Plans are paid in advance. Plans automatically renew at standard pricing."
    },
    plans: {
      basic: "Talentis",
      connect: "Talentis Connect"
    },
    offers: {
      launch: {
        title: "Launch offer",
        description:
          "Sign-up to an annual subscription before the end of May 2021 and benefit from significant savings"
      },
      july2021: {
        title: "Upgrade in July to receive 25% off",
        description: "Add your promo code on the payment page:",
        yearlyCode: "Yearly plan: ",
        monthlyCode: "Monthly plan: ",
        subtext: "Discount only applies to the initial billing period."
      },
      august2021: {
        title: "Upgrade now to receive 25% off"
      }
    },
    paymentDetails: {
      billingAddress: "Billing Address",
      cardDetails: "Card details",
      nameOnCard: "Name on card",
      cardNumber: "Card number",
      expiryDate: "Expiry date (MM/YY)",
      securityCode: "Security code (CVV)",
      billingInformation: "Billing information",
      addressFirstLine: "1st line of address",
      addressCity: "City",
      addressPostalCode: "ZIP / Postal code",
      vatNumber: "VAT number (optional)",
      agreeTo: "Agree to",
      termsAndConditions: "Terms and Conditions",
      termsAndConditionsError: "You must agree to the Terms and Conditions",
      purchase: "Purchase plan",
      byConfirming:
        "By confirming your purchase, you allow Talentis to charge your card for this payment and future payments when your plan renews.",
      loading: "Processing card details, please wait",
      loadingPlanUpdate: "Upgrading plan, please wait",
      legalSubtext:
        "Plans are paid in advance. Plans automatically renew at standard pricing."
    },
    promoCode: {
      promoCode: "Promo code",
      promoCodeAdded: "Promo code added",
      promoCodeError: "Invalid promo code, please try again.",
      promo: "Promo"
    },
    orderSummary: {
      totalCostOfPlan: "Total cost of plan",
      user: "user",
      users: "users",
      oneMonth: "1 month",
      twelveMonths: "12 months",
      taxes: "Taxes",
      discount: "Discount"
    },
    orderSuccess: {
      paymentSuccessful: "Payment successful",
      planUpdatedSuccessful: "Plan confirmed",
      youHavePurchased: "You've purchased:",
      planUpdated: "Your Plan:"
    },
    paymentError: {
      paymentFailed: "Payment failed",
      addressFailed: "Oops...",
      thereIsAnIssue:
        "There is an issue processing your payment. Please try a different card or contact your bank/card issuer."
    },
    exitPrompt: {
      title: "Are you sure you want to exit your upgrade?",
      subtitle:
        "Your details will not be saved and you'll need to start the process again.",
      exit: "Yes, exit upgrade",
      continue: "No, continue with upgrade"
    },
    features: {
      unlimitedAccess: "Unlimited access to the Talentis TalentGraph",
      contactCreditsAvailableForPurchase:
        "Contact Credits available for purchase",
      everythingInTheTalentisPlan: "Everything in the Talentis Plan",
      contactCreditsIncluded: "{0} Contact Credits included",
      per: "per",
      user: "user",
      perMonth: "per month",
      significantDiscountOnAdditional:
        "Significant discount on additional Contact Credits compared to Talentis Plan",
      purchaseMoreContactCredits: "Ability to purchase more contact credits"
    },
    currencies: {
      usd: "US dollar",
      gbp: "GBP",
      eur: "Euro",
      aud: "AUD",
      pln: "Polish Zloty"
    }
  },
  homepage: {
    welcome: "Welcome to Talentis",
    loveToHearYourOpinion: "We'd love to hear what you think of Talentis",
    tellUsAboutYourExperience:
      "Tell us about your experience and what can be improved",
    seeOnDashboard: "What would you like to see on your dashboard?",
    provideFeedback: "Provide feedback",
    completeOurSurvey: "Complete our survey",
    noActiveAssignments: "No active assignments",
    addAssignment: "Add an assignment",
    viewAllAssignments: "View all assignments",
    activeAssignments: "Active assignments"
  },
  seo: {
    title: "{0} | Talentis – Recruitment software",
    assignments: "Assignments",
    contacts: "Contacts",
    team: "Team",
    search: "Search",
    addNewProfile: "Add new profile",
    addNewContact: "Add new contact",
    addNewAssignment: "Add new assignment",
    createAssignment: "Create assignment",
    signUp: "Sign up",
    inviteUser: "Invite user",
    register: "Register",
    dashboard: "Dashboard"
  },
  talentGraphSearch: {
    searchCriteria: "Search criteria",
    addCriteriaToFindPeople:
      "Add your search criteria to find people in the TalentGraph",
    filters: {
      jobTitle: "Job title",
      keywords: "Keywords",
      location: "Location",
      distance: "Distance",
      unit: "Unit",
      boardExperience: "Board director experience (independent non-exec)",
      likelyToMove: "May be more open to recruiter contact",
      company: "Company"
    },
    distanceUnits: {
      KM: "km",
      Miles: "miles"
    },
    comingSoon: "Coming soon!",
    additionalSearchCriteria: "Additional search criteria",
    experience: "{0} at {1}",
    present: "Present",
    loadingCandidates: "Loading candidates",
    findingMatches: "Finding matches, please wait",
    foundResultsFromTalentGraph: "{0} results from the TalentGraph",
    foundResults: "{0} results",
    updatingPersonAssignment: "Adding person to an assignment",
    selectAssignment: "Select assignment",
    selectAssignmentToLink: "Select assignment to link this person",
    linkToAssignment: "Link to assignment",
    linkedTo: "Linked to {0}",
    errors: {
      jobTitleLength: "Job title must be at most 50 characters",
      keywordLength: "Keyword must be at most 50 characters",
      locationLength: "Town, City or State must be at most 50 characters",
      countryLength: "Country must be at most 50 characters",
      limitsError: {
        searchJobTitle:
          "There is a limit to {0} job titles can be entered and searched",
        searchCountry:
          "There is a limit to {0} countries can be entered and searched",
        searchLocation:
          "There is a limit to {0} towns, cities, states can be entered and searched",
        searchKeyword:
          "There is a limit to {0} keywords can be entered and searched",
        searchCompany:
          "There is a limit to {0} companies can be entered and searched",
        boardLevelExperience: "",
        likelyToMove: "",
        locationDistance: "",
        locationDistanceUnit: "",
        keywordsSearchUsingORLogic: "",
        searchCurrent: "",
        searchJobTitleLogic: "",
        searchCompanyLogic: "",
        default: "There is a limit to {0} things can be entered and searched"
      }
    },
    placeholders: {
      jobTitle: "Type job title and press enter",
      keywords: "Type keywords and press enter",
      companyNames: "Type company name and press enter"
    },
    noResults: "No results match your criteria.",
    pleaseEditCriteria:
      "Please edit your criteria and search again, or try one of the methods below.",
    clearAll: "Clear all",
    keywordLogicSelector: {
      label: "Find profiles that mention:",
      atLeastOne: "At least one keyword",
      all: "All keywords"
    },
    viewResults: "View results",
    reloadSearch: "Reload last search",
    searchLogic: {
      current: "Current",
      past: "Past",
      either: "Either"
    },
    findMore: {
      label: "Find more candidates",
      useThe: "Use the",
      talentisBrowserExtension: "Talentis browser extension",
      toLinkPeople:
        "to link people to your assignment when searching on other sites",
      useOtherMethods:
        "Alternatively, try our other methods for finding candidates",
      findPeopleOnPopularSites:
        "Find people on popular sites, then use the Talentis browser extension to link them to your assignment",
      guide: "Guide to finding candidates",
      watchGuide: "Watch our guide on how to find candidates",
      searchTheWeb: "Search the web",
      searchLinkedIn: "Search LinkedIn"
    },
    logic: {
      and: "AND",
      or: "OR"
    },
    addKeywordField: "Add keyword field"
  },
  notPermission: {
    youDontHave: "You don't have permisson to access this page.",
    toGainAccess:
      "To gain access an Owner or Manager user in your team needs to change your role."
  },
  billing: {
    title: "Plan and billing",
    currentPlan: {
      title: "Current plan",
      numberOfUsers: "{0} users",
      usedUsers: "Using {0} of {1}",
      manageTeam: "Manage team",
      billed: {
        year: "Billed yearly",
        month: "Billed monthly"
      },
      renewalDate: "Renewal and payment date {0}",
      renewalCost:
        "Renewal cost {0} ({1} per user, per month, excluding any applicable taxes).",
      freeTrial: "Free trial",
      endDate: "Ends {0}",
      daysRemaining: "{0} days remaining",
      afterYourTrial:
        "After your free trial ends you won't be able to use Talentis unless you upgrade to a paid plan.",
      dataDeleted: "Your data will be deleted after 30 days."
    },
    updateBillingCTA: {
      title: "Change your plan or purchase contact credits",
      body: "Contact us to change or cancel your plan, add or reduce users, or purchase contact credits.",
      us: "Americas:",
      uk: "UK / EMEA:",
      australia: "Australia / NZ:",
      emailUs: "Email us"
    }
  },
  clientPortal: {
    label: "Client portal",
    create: "Create client portal",
    editingMode: "You are editing the client view for an assignment",
    close: "Close client view",
    share: "Share",
    addLogo: "Add your logo",
    addClientLogo: "Add client logo",
    candidates: "Candidates",
    showAll: "Show all in client view",
    show: "Show in client view",
    selectedCandidates: "{0} of {1}",
    goBack: "Back to assignments",
    access: {
      copyTheUniqueAccess:
        "Copy the unique access details for your client contact from the list below. These details only allow access to this report.",
      sharingWithYourClientContact: "Sharing with your client contact",
      clientContactsWithAccess: "Client contacts with access",
      clientContact: "Client contact",
      copyAccessDetails: "Copy access details",
      reportShareSettings: "Client report share settings",
      clientContactEmailAccess: "Client contact's email address",
      createAccessDetails: "Create access details",
      onlyUnique:
        "A unique username and password will be generated for this email address, which can only be used to view this report.",
      youCanRemove:
        "You can remove access at any time. The password can be reset by your client from the log in screen.",
      removeAccess: "Remove access"
    },
    commentary: {
      title: "Commentary"
    },
    notes: {
      selectNoteFor: "Select a note to show for {0}",
      newNote: "New note",
      saveChanges: "Save changes",
      dontShow: "Don't show a note"
    }
  },
  pageNotFound: {
    fourZeroFour: "404",
    pageNotFound: "Page Not Found",
    goHome: "Go Home"
  }
}
