import { ModalNames } from "setup/modal/modal.definitions"

export type SingleModalEntry = {
  isOpen: boolean
  element: JSX.Element
}

export type OpenModals = {
  [name: string]: SingleModalEntry
}

export type ModalState = {
  isOpen: boolean
  activeModal: string
  openModals: OpenModals
}

export type ModalContextValues = ModalState & {
  open: OpenModal
  close: CloseModal
  closeAll: CloseAllModals
  onClose: OnClose
  removeOnCloseCallback: RemoveOnCloseCallback
}

export enum ModalActionTypes {
  Open = "open",
  Close = "close",
  CloseAll = "close-all"
}

export type ModalAction = {
  type: ModalActionTypes
  payload?: any
}

export type ModalCallback = () => void
export type ModalCallbackCollection = {
  name: string
  callback: ModalCallback
}[]
export type OpenModal = (name: ModalNames, modal: JSX.Element) => void
export type CloseModal = (name: ModalNames) => void
export type CloseAllModals = () => void
export type OnClose = (name: ModalNames, callback: ModalCallback) => void
export type RemoveOnCloseCallback = (
  name: ModalNames,
  callback: ModalCallback
) => void
