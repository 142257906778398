import React, { useCallback } from "react"
import { useParams } from "react-router-dom"
import { Loader } from "@ikiru/talentis-fpc"
// import { CandidatesEndpoints } from "setup/api/endpoints/endpoints"
import { apiRequest } from "setup/api/api"
import { useAssignmentData } from "../hooks/useAssignmentData"
// import { useCandidates } from "views/assignments/hooks/useCandidates"
import { ClientPortalConfigurationContext } from "./context"
import {
  ClientPortalConfigurationModuleLocationParams,
  ClientPortalConfigurationModuleProps
} from "./types"
import { groupCandidates } from "../utils"
import { Nullable } from "tsdef"
import { Note } from "../types"
import { AssignmentsEndpoints } from "setup/api/endpoints/endpoints"

const ClientPortalConfigurationModule = (
  props: ClientPortalConfigurationModuleProps
) => {
  const { children } = props
  const { assignmentId = "" } =
    useParams<ClientPortalConfigurationModuleLocationParams>()

  const photos: any[] = []
  const isCandidatesLoading = false

  const { isLoading: isAssignmentLoading, assignment } = useAssignmentData({
    assignmentId
  })

  let candidates = assignment?.candidates?.candidates || []
  candidates = candidates.map((candidate: any) => {
    const dataPoolPerson = candidate?.linkPerson?.dataPoolPerson
    const localPerson = candidate?.linkPerson?.localPerson

    return {
      ...candidate,
      normalizedPersonData: {
        company: dataPoolPerson?.company || localPerson?.company,
        currentJob: dataPoolPerson?.currentJob || localPerson?.currentJob,
        dataPoolId: dataPoolPerson?.dataPoolId || localPerson?.dataPoolId,
        id: dataPoolPerson?.id || localPerson?.id,
        jobTitle: dataPoolPerson?.jobTitle || localPerson?.jobTitle,
        linkedInProfileUrl:
          dataPoolPerson?.linkedInProfileUrl || localPerson?.linkedInProfileUrl,
        location: dataPoolPerson?.location || localPerson?.location,
        name: dataPoolPerson?.name || localPerson?.name,
        previousJobs: dataPoolPerson?.previousJobs || localPerson?.previousJobs,
        webSites: dataPoolPerson?.webSites || localPerson?.webSites
      }
    }
  })

  const groupedCandidates = groupCandidates(candidates)

  const toggleCandidatesInClientView = useCallback(
    (candidateIds: string[], showInClientView: boolean) => {
      candidateIds.forEach((id) => {
        apiRequest.get({
          endpoint: AssignmentsEndpoints.AssignmentDetail,
          endpointParams: id
        })
        /*apiRequest.patch({
          endpoint:  CandidatesEndpoints.Root,
          endpointParams: id,
          data: {
            showInClientView
          }
        })*/
      })

      const newCandidates = candidates.map((candidate: any) =>
        candidateIds.includes(candidate.id)
          ? { ...candidate, showInClientView }
          : candidate
      )
      const groupedCandidates = groupCandidates(newCandidates)

      // updateCandidates({ candidates: newCandidates, groupedCandidates })
    },
    [candidates /*updateCandidates*/]
  )

  const updateCandidateNote = useCallback(
    (candidateId, note: Nullable<Note>) => {
      const newCandidates = candidates.map((candidate: any) =>
        candidate.id === candidateId
          ? {
              ...candidate,
              linkSharedNote: note,
              sharedNoteId: note?.id || null
            }
          : candidate
      )
      const groupedCandidates = groupCandidates(newCandidates)

      // updateCandidates({ candidates: newCandidates, groupedCandidates })
    },
    [candidates /*updateCandidates*/]
  )

  return (
    <ClientPortalConfigurationContext.Provider
      value={{
        // @ts-ignore
        photos,
        assignment,
        candidates,
        groupedCandidates,
        isAssignmentLoading,
        isCandidatesLoading,
        updateCandidateNote,
        toggleCandidatesInClientView
      }}
    >
      {isAssignmentLoading ? <Loader /> : children}
    </ClientPortalConfigurationContext.Provider>
  )
}

export default React.memo(ClientPortalConfigurationModule)
