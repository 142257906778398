import React from "react"
import {
  Link,
  Button,
  Flex,
  BodyText,
  H2,
  CrossIcon
} from "@ikiru/talentis-fpc"
import { ConfirmationModalWrapper, CloseButton } from "./styles"
import { useIntl } from "react-intl"

type ConfirmationModalProps = {
  title: React.ReactNode
  subtitle?: React.ReactNode
  confirmButtonLabel: React.ReactNode
  cancelButtonLabel: React.ReactNode
  onClose: VoidFunction
  onCancel: VoidFunction
  onConfirm: VoidFunction
}

export const ConfirmationModal = ({
  title,
  subtitle,
  confirmButtonLabel,
  cancelButtonLabel,
  onClose,
  onCancel,
  onConfirm
}: ConfirmationModalProps) => {
  const intl = useIntl()
  return (
    <ConfirmationModalWrapper>
      <CloseButton
        mode="standard-white"
        size="action-medium"
        onClick={onClose}
        title={intl.formatMessage({
          id: "misc.close",
          defaultMessage: "Close"
        })}
      >
        <CrossIcon
          title={intl.formatMessage({
            id: "misc.close",
            defaultMessage: "Close"
          })}
        />
      </CloseButton>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p="s"
      >
        <H2 mt="none" textAlign="center" color="grey.dark">
          {title}
        </H2>
        {subtitle && (
          <BodyText textAlign="center" mt="0" px="m">
            {subtitle}
          </BodyText>
        )}
        <Button onClick={onConfirm} mt="s">
          {confirmButtonLabel}
        </Button>
        <Link onClick={onCancel} mt="s" mb="none">
          {cancelButtonLabel}
        </Link>
      </Flex>
    </ConfirmationModalWrapper>
  )
}
