import { createIntl, createIntlCache } from "react-intl"
export { IntlProvider } from "./IntlModule"

const cache = createIntlCache()

export const locale =
  Intl.DateTimeFormat().resolvedOptions().locale || navigator.language

export const messages = {}

export const intl = createIntl(
  {
    locale,
    messages
  },
  cache
)
