import { useCallback, useEffect, useState } from "react"
import { Nullable } from "tsdef"
import { apiRequest } from "setup/api/api"
import { parseMomentDate } from "utils/moment"
import { AssignmentsEndpoints } from "setup/api/endpoints/endpoints"

type UseAssignmentDataParams = {
  assignmentId?: string
  onError?: VoidFunction
}

const useAssignmentData = ({
  assignmentId,
  onError
}: UseAssignmentDataParams) => {
  const [isLoading, setIsLoading] = useState(false)
  const [assignment, setAssignment] = useState<Nullable<any>>(null)

  const getAssignmentData = useCallback(
    async (assignmentId: string) => {
      setIsLoading(true)
      const [error, response] = await apiRequest.get({
        endpoint: AssignmentsEndpoints.AssignmentDetail,
        endpointParams: assignmentId
      })

      if (error) {
        onError?.()
      } else {
        const data = response?.data

        const startDate = parseMomentDate(data?.startDate)

        setAssignment({ ...data, startDate })
      }

      setIsLoading(false)
    },
    [onError]
  )

  useEffect(() => {
    if (assignmentId) {
      getAssignmentData(assignmentId)
    }
  }, [assignmentId, getAssignmentData])

  return {
    isLoading,
    assignment
  }
}

export { useAssignmentData }
