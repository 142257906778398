import React from "react"
import get from "lodash/get"
import { Nullable } from "tsdef"
import { CandidateBox } from "@ikiru/talentis-fpc"
import { useCandidateRecord } from "./hooks"
import { messages } from "setup/messages/messages"

type CandidateProps = {
  candidate: any
  photo?: Nullable<string>
  onToggle?: (checked: boolean) => void
}

const CandidateRecord = ({ candidate, onToggle }: CandidateProps) => {
  const { jobs, websites } = useCandidateRecord(candidate)

  const note = candidate?.commentary?.data && candidate?.commentary?.showToClient
    ? {
      title: messages.clientPortal.commentary.title,
      description: candidate?.commentary?.data
    }
    : null

  return (
    <CandidateBox
      status={get(
        messages,
        `person.assignments.interviewProgress.statuses.${candidate.interviewProgressState?.status}`
      )}
      selectable
      selected
      onSelect={onToggle}
      selectText={messages.clientPortal.show}
      photoUrl={candidate.photoUrl}
      name={candidate.normalizedPersonData?.name}
      location={candidate.normalizedPersonData?.location}
      key={candidate.id}
      hideHeader
      {...{ jobs, websites, note }}
    />
  )
}

export default React.memo(CandidateRecord)
