import { BodyText, colors, Flex, SmallText } from "@ikiru/talentis-fpc"
import { RouterLink } from "components/RouterLink/RouterLink"
import { AssignmentDataBox, BadgeStyled } from "../../styles"
import { useIntl } from "react-intl"
import { Assignment, CandidateStageCount } from "../../assignment.types"

type AssignmentsProps = {
  assignments: Assignment[]
}

const Assignments = ({ assignments }: AssignmentsProps) => {
  const intl = useIntl()

  return (
    <>
      {assignments.map((value, index, array) => (
        <Flex
          key={value.id}
          bg="white.standard"
          width="100%"
          flexDirection="row"
          style={{
            borderRadius: "10px",
            border: "2px solid " + colors.grey.light
          }}
          mb={index === array.length - 1 ? "none" : "s"}
        >
          <AssignmentDataBox>
            <RouterLink
              my="none"
              // @ts-ignore
              to={`/assignment/${value.assignmentId}`}
              fontWeight={600}
            >
              {value.name}
            </RouterLink>
          </AssignmentDataBox>
          <AssignmentDataBox>
            <BodyText fontWeight="600">{value.companyName}</BodyText>
          </AssignmentDataBox>
          <AssignmentDataBox>
            <BodyText>{value.location}</BodyText>
          </AssignmentDataBox>
          <AssignmentDataBox>
            <Flex flexDirection="column">
              {!!value.assignmentCandidateStageCount &&
                (
                  Object.keys(value.assignmentCandidateStageCount) as [
                    keyof CandidateStageCount
                  ]
                ).map((objKey) => {
                  const valueOfStatus = (
                    value.assignmentCandidateStageCount as CandidateStageCount
                  )[objKey]
                  return (
                    !!valueOfStatus && (
                      <Flex
                        key={objKey}
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        mb="xxs"
                      >
                        <BadgeStyled
                          bg="grey.standard"
                          color="white.standard"
                          height={20}
                        >
                          {valueOfStatus}
                        </BadgeStyled>
                        <SmallText color="grey.dark" ml="xxs" mb="none">
                          {intl.formatMessage({
                            id: "messages.assignment." + objKey,
                            defaultMessage: objKey
                          })}
                        </SmallText>
                      </Flex>
                    )
                  )
                })}
            </Flex>
          </AssignmentDataBox>
        </Flex>
      ))}
    </>
  )
}

export default Assignments
