import { FPCTheme, theme } from "@ikiru/talentis-fpc"
import { ThemeProvider } from "styled-components"

export const StyledProvider: React.FC = ({ children }) => {
  return (
    <FPCTheme>
      <ThemeProvider {...{ theme }}>{children}</ThemeProvider>
    </FPCTheme>
  )
}
