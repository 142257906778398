import styled from "styled-components"
import { Div } from "@ikiru/talentis-fpc"

type Props = {
  isSidebar?: boolean
  noPadding?: boolean
  maxWidth?: number
}

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: ${({ theme }) => theme.colors.grey.lightest};
  padding: ${({ theme }) => `${theme.space.xs}px ${theme.space.l}px 0`};
  border-bottom: 3px solid;
  border-color: ${({ theme }) => theme.colors.grey.light};
  box-sizing: border-box;
`

export const LayoutWrapper = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  height: 100%;
  position: relative;
  overflow: hidden;
`

export const LayoutMain = styled.main<Props>`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  ${({ theme, noPadding }) =>
    noPadding ? `padding: 0;` : `padding: ${theme.space.m}px;`}
  ${({ theme, noPadding }) => `
  ${theme.mediaQueries.md} {
    padding: ${noPadding ? 0 : theme.space.l}px;
  }`}
`

export const MainWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  bottom: 0;
`

export const Inner = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${({ maxWidth }: Props) => (maxWidth ? `${maxWidth}px` : "auto")};
`

export const NavigationWrapper = styled.div<Props>`
  position: absolute;
  height: ${({ isSidebar }) => (isSidebar ? "100%" : "auto")};
  width: ${({ isSidebar }) => (isSidebar ? "auto" : "100%")};
  z-index: 5;
`

export const PageContentWrapper = styled.div<Props>`
  width: 100%;
  ${({ theme, noPadding }) =>
    noPadding
      ? `
      height: 100%;
      padding-bottom: 0;
    `
      : `
      padding-bottom: ${theme.space.xxl}px;
      height: auto;
    `}
`
