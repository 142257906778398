import { AxiosRequestConfig } from "axios"

export class PatchHeaders {
  public resolve = (config: AxiosRequestConfig) => {
    /*
        Content-Type headers cant be overridden using axios defaults
        Issue: https://github.com/axios/axios/issues/2623
    */
    if (config.method === "patch") {
      config.headers["Content-Type"] = "application/merge-patch+json"
    }

    return config
  }
}
