import {
  ModalState,
  ModalAction,
  ModalActionTypes,
  ModalContextValues
} from "setup/modal/modal.types"

export const initialModalState: ModalState = {
  isOpen: false,
  activeModal: "",
  openModals: {}
}

export const initialModalContextValues: ModalContextValues = {
  ...initialModalState,
  open: () => {},
  close: () => {},
  closeAll: () => {},
  onClose: () => {},
  removeOnCloseCallback: () => {}
}

export const modalReducer = (state: ModalState, action: ModalAction) => {
  const { type, payload } = action
  switch (type) {
    case ModalActionTypes.CloseAll:
      return {
        ...state,
        openModals: {},
        isOpen: false
      }
    case ModalActionTypes.Close:
      const { [payload.name]: _, ...remainingOpenModals } = state.openModals
      return {
        ...state,
        openModals: remainingOpenModals,
        isOpen: Boolean(Object.keys(remainingOpenModals).length)
      }
    case ModalActionTypes.Open:
      return {
        ...state,
        activeModal: payload.name,
        openModals: {
          ...state.openModals,
          [payload.name]: {
            isOpen: true,
            element: payload.element
          }
        },
        isOpen: true
      }
    default:
      return state
  }
}
