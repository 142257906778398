import { useCallback } from "react"
import { apiRequest } from "setup/api/api"
import { UsersEndpoints } from "setup/api/endpoints/endpoints"
import { useAuth } from "oidc-react"

export enum UserRoles {
  Owner = "owner",
  Admin = "admin",
  TeamMember = "teamMember"
}

export interface TalentisUser {
  userRole: UserRoles
  searchFirmId: string
  searchFirmName: string
}

export const useTalentisUser = () => {
  const auth = useAuth()

  const getTalentisUser = useCallback(
    async (token?: string): Promise<TalentisUser> => {
      let additionalConfig = {}

      if (token) {
        additionalConfig = {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      }

      const [, response] = await apiRequest.get({
        endpoint: UsersEndpoints.Me,
        config: additionalConfig
      })

      if (response?.status === 204 && auth.signOutRedirect) {
        auth.signOutRedirect()
      }

      return response?.data
    },
    [auth]
  )

  return { getTalentisUser }
}
