import React from "react"
import { IntlProvider as IntlProviderWrapper } from "react-intl"
import ESupportedLanguages from "./definitions"
import AppLocale from "./AppLocale"

export const IntlProvider: React.FC = ({ children }) => {
  const currentLang = ESupportedLanguages.English
  return (
    <IntlProviderWrapper locale={currentLang} messages={AppLocale[currentLang]}>
      {children}
    </IntlProviderWrapper>
  )
}

export default IntlProvider
