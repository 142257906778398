import { AxiosInstance, AxiosRequestConfig } from "axios"

export const setCommonHeaders = (
  axios: AxiosInstance,
  name: string,
  value: string
) => (axios.defaults.headers.common[name] = value)

export const setBearerToken = (axios: AxiosInstance, token: string) =>
  setCommonHeaders(axios, "Authorization", "Bearer " + token)

export const setNewAuthTokenForCurrentConfig = (
  config: AxiosRequestConfig,
  token: string
) => (config.headers["Authorization"] = `Bearer ${token}`)
