export enum InterviewProgressStage {
  Identified = "identified",
  Screening = "screening",
  InternalInterview = "internalInterview",
  Shortlist = "shortList",
  FirstClientInterview = "firstClientInterview",
  SecondClientInterview = "secondClientInterview",
  ThirdClientInterview = "thirdClientInterview",
  Offer = "offer",
  Placed = "placed",
  Archive = "archive"
}

export const interviewStagesOrder: InterviewProgressStage[] = [
  InterviewProgressStage.Placed,
  InterviewProgressStage.Offer,
  InterviewProgressStage.ThirdClientInterview,
  InterviewProgressStage.SecondClientInterview,
  InterviewProgressStage.FirstClientInterview,
  InterviewProgressStage.Shortlist,
  InterviewProgressStage.InternalInterview,
  InterviewProgressStage.Screening,
  InterviewProgressStage.Identified,
  InterviewProgressStage.Archive
]
