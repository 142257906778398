import styled from "styled-components"
import { Flex, Badge, fontFamilies } from "@ikiru/talentis-fpc"

export const AssignmentDataBox = styled(Flex)`
  width: 100%;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.xxs}px;

  &:last-child {
    margin-bottom: ${({ theme }) => theme.space.none}px;
  }

  ${({ theme }) => `
    ${theme.mediaQueries.sm} {
      margin-bottom: ${theme.space.none}px;
      padding: ${theme.space.s}px ${theme.space.xs}px;
      width: 25%;
      border-right: 1px solid ${theme.colors.grey.light};
    }
  `}

  &:last-child {
    border-right: 0px;
  }
`

export const BadgeStyled = styled(Badge)`
  font-size: 16px;
  font-weight: 400;
  font-family: ${fontFamilies.sourceSans};
`
