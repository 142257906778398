import React, { useCallback, useMemo } from "react"
import { InterviewProgressStage } from "../../constants/interview-stages-order"
import { useClientPortalConfiguration } from "../../module/context"

const useStageCandidates = (stage: InterviewProgressStage) => {
  const { groupedCandidates, photos, toggleCandidatesInClientView } =
    useClientPortalConfiguration()

  const candidates = useMemo(
    () => groupedCandidates?.[stage] || [],
    [groupedCandidates, stage]
  )

  const selectedCandidatesCount = useMemo(
    () =>
      candidates.filter((candidate: any) => candidate.showInClientView).length,
    [candidates]
  )

  const areAllSelected = useMemo(
    () =>
      selectedCandidatesCount === candidates.length &&
      Boolean(candidates.length),
    [selectedCandidatesCount, candidates]
  )

  const onToggleStage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target

      const candidateIds = candidates
        .filter((candidate: any) => candidate.showInClientView !== checked)
        .map((candidate: any) => candidate.id)

      toggleCandidatesInClientView(candidateIds, checked)
    },
    [candidates, toggleCandidatesInClientView]
  )

  const onToggleCandidate = useCallback(
    (candidate) => (checked: boolean) => {
      toggleCandidatesInClientView([candidate.id], checked)
    },
    [toggleCandidatesInClientView]
  )

  return {
    candidates,
    areAllSelected,
    photos,
    selectedCandidatesCount,
    onToggleStage,
    onToggleCandidate
  }
}

export { useStageCandidates }
