import axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import { PatchHeaders } from "./interceptors/request/PatchHeaders"

const requestInterceptors: any[] = [new PatchHeaders()]

type ResolveCallbackType = (config: AxiosRequestConfig) => AxiosRequestConfig

export const registerInterceptors = (axiosInstance: AxiosInstance = axios) => {
  requestInterceptors.forEach((instance) => {
    const { resolve } = instance
    const boundResolve = resolve.bind(
      instance
    ) as unknown as ResolveCallbackType

    axiosInstance.interceptors.request.use(boundResolve)
  })
}
