import { useState, useEffect } from "react"

const isClient = typeof window === "object"
const isAPISupported = (api: string) => api in window

const errorMessage =
  "matchMedia is not supported, this could happen both because window.matchMedia is not supported by" +
  " your current browser or you're using the useMediaQuery hook whilst server side rendering."

export const useMediaQuery = (mediaQuery: string) => {
  const [isVerified, setIsVerified] = useState(
    !!window?.matchMedia?.(mediaQuery)?.matches || false
  )

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery)
    const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches)

    mediaQueryList.addEventListener("change", documentChangeHandler)

    documentChangeHandler()
    return () => {
      mediaQueryList.removeEventListener("change", documentChangeHandler)
    }
  }, [mediaQuery])

  if (!isClient || !isAPISupported("matchMedia")) {
    console.warn(errorMessage)
    return undefined
  }

  return isVerified
}
