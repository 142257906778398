import { mainRoute, OIDCServerUrl, OIDCSignInRedirectRouteUrl } from "./consts"
import { getRouteWithPageUrl } from "./utils"

export const oidcConfig = {
  onSignIn: () => {
    window.location.href = "/"
  },
  clientId: "PortalWebAppClient",
  responseType: "code",
  scope: "openid profile ParsnipsApi",
  authority: OIDCServerUrl,
  silentRedirectUri: mainRoute,
  redirectUri: getRouteWithPageUrl(OIDCSignInRedirectRouteUrl),
  postLogoutRedirectUri: getRouteWithPageUrl(OIDCSignInRedirectRouteUrl),
  loadUserInfo: true,
  includeIdTokenInSilentRenew: true,
  revokeAccessTokenOnSignout: true,
  monitorSession: true
}
