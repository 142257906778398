import styled from "styled-components"

export const ErrorMessage = styled.p`
  margin-top: 90px;
  font-size: 70px;
  margin-left: 40px;
  color: ${({ theme }) => theme.colors.grey.light};

  @media (max-width: 768px) {
    font-size: 50px;
    margin-left: 20px;
  }

  @media (max-width: 468px) {
    font-size: 30px;
    margin-left: 0px;
  }
`
