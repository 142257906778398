import React from "react"

import { AppProviders } from "setup/app-providers"
import { Router } from "setup/router/Router"

const App: React.FC = () => {
  return (
    <AppProviders>
      <Router />
    </AppProviders>
  )
}

export default App
