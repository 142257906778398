import { useAuth } from "oidc-react"
import { useEffect, useState } from "react"
import { setBearerToken } from "./utils/set-headers"
import { AuthProps } from "./api.types"
import React from "react"

export const Auth = (props: AuthProps) => {
  const { userData } = useAuth()
  const [authHeaderAdded, setAuthHeaderAdded] = useState(false)

  useEffect(() => {
    if (userData && userData.access_token) {
      setBearerToken(props.api, userData.access_token)
      setAuthHeaderAdded(true)
    }
  }, [props.api, userData])

  return <>{authHeaderAdded && props.children}</>
}
