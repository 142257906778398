import ESupportedLanguages from "./definitions"
import flattenObject from "utils/functions/flattenObject"
import en from "./languages/en.json"

const languages = {
  [ESupportedLanguages.English]: en
}

const AppLocale: { [key: string]: { [key: string]: string } } = {
  ...Object.entries(ESupportedLanguages).reduce((all, [, value]) => {
    return {
      ...all,
      [value]: flattenObject(languages[value])
    }
  }, {})
}

export default AppLocale
