import React, { useCallback, useMemo } from "react"
import { Note } from "../../types"
import { apiRequest } from "setup/api/api"
import { ModalNames } from "setup/modal/modal.definitions"
import { Nullable } from "tsdef"
import { useModal } from "utils/hooks/use-modal"
import { useClientPortalConfiguration } from "../../module/context"
import { transformJobs } from "../../utils"

const useCandidateRecord = (candidate: any) => {
  const { open: openModal, close: closeModal } = useModal()
  const { updateCandidateNote } = useClientPortalConfiguration()

  const jobs = useMemo(
    () =>
      transformJobs(
        candidate?.normalizedPersonData?.previousJobs,
        candidate?.normalizedPersonData?.currentJob
      ),
    [candidate]
  )

  const websites = useMemo(
    () => [
      /*...(candidate?.normalizedPersonData?.linkedInProfileUrl
        ? [
            {
              type: "linkedIn",
              url: candidate?.normalizedPersonData?.linkedInProfileUrl
            }
          ]
        : []),*/
      ...(candidate.normalizedPersonData?.webSites || [])
    ],
    [candidate]
  )

  return { jobs, websites }
}

export { useCandidateRecord }
