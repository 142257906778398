import React, { useEffect, useRef } from "react"
import { useHistory } from "react-router"
import { getMinWidthMediaQuery, H5, Button, Flex } from "@ikiru/talentis-fpc"
import { useMediaQuery } from "utils/hooks/use-media-query"
import * as Styled from "./layout.styled"
import { navigationSelectors } from "./definitions"
import { useIntl } from "react-intl"
import { useAuth } from "oidc-react"

type LayoutProps = {
  children: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
  const mediaQuery = getMinWidthMediaQuery("md")
  const isLarge = useMediaQuery(mediaQuery)
  const history = useHistory()
  const intl = useIntl()
  const auth = useAuth()
  const mainLayoutRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const unlisten = history.listen(() => {
      mainLayoutRef.current?.scrollTo?.({ top: 0, behavior: "smooth" })
    })

    return () => {
      unlisten()
    }
  }, [history, mainLayoutRef])

  return (
    <Styled.LayoutWrapper>
      <Styled.HeaderContainer>
        <Flex justifyContent="space-between" width="100%">
          <H5 color="grey.dark" ml="none">
            {intl.formatMessage({
              id: "messages.clientPortal",
              defaultMessage: "Client portal"
            })}
          </H5>
          <Flex alignItems="center">
            <Button
              mode="standard-green"
              onClick={() => auth.signOutRedirect()}
            >
              {intl.formatMessage({
                id: "messages.logOut",
                defaultMessage: "Log out"
              })}
            </Button>
          </Flex>
        </Flex>
      </Styled.HeaderContainer>
      <Styled.MainWrapper
        id={navigationSelectors.mainContainer}
        ref={mainLayoutRef}
        isSidebar={Boolean(isLarge)}
      >
        <Styled.LayoutMain noPadding={false}>
          <Styled.PageContentWrapper noPadding={false}>
            {children}
          </Styled.PageContentWrapper>
        </Styled.LayoutMain>
      </Styled.MainWrapper>
    </Styled.LayoutWrapper>
  )
}
