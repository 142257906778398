import styled from "styled-components"
import { Button } from "@ikiru/talentis-fpc"

export const ConfirmationModalWrapper = styled.div`
  background: ${({ theme }) => {
    console.log({ theme })
    // return theme.colors.grey.light
    return "#D5DEE7"
  }};
  padding: ${({ theme }) => theme.space.m}px;
  width: 100vw;
  height: 100vh;
  position: relative;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  ${({ theme }) => `
    ${theme.mediaQueries.sm} {
      width: auto;
      height: auto;
      min-height: 320px;
      max-width: 390px;
    }
  `}
`

export const CloseButton = styled(Button)`
  position: absolute;
  top: ${({ theme }) => theme.space.xs}px;
  right: ${({ theme }) => theme.space.xs}px;

  ${({ theme }) => `
    ${theme.mediaQueries.sm} {
      top: ${theme.space.m}px;
      right: ${theme.space.m}px;
    }
  `}
`
