import { useEffect, useState } from "react"
import { Redirect, Route, Router as ReactRouter } from "react-router-dom"
import { Test } from "Test"
import { RouterUrl } from "./routes"
import { browserHistory } from "./setup"
import { ListAssignment } from "../../views/assignments/pages/list-assignment/ListAssignment"
import ClientPortalConfigurationWrapper from "../../views/assignments/pages/assignment-detail/ClientPortalConfigurationWrapper"
import { Layout } from "../../components/visual/layout/Layout"
import { useIntl } from "react-intl"
import { Helmet } from "react-helmet"
import { TalentisUser, useTalentisUser } from "../../utils/hooks/auth.hooks"
import { CustomSwitch } from "./CustomSwitch"
import React from "react"

export const Router = () => {
  const intl = useIntl()
  const { getTalentisUser } = useTalentisUser()
  const [user, setUser] = useState<TalentisUser | null>(null)

  useEffect(() => {
    getTalentisUser().then((user) => {
      setUser(user)
    })
  }, [getTalentisUser])

  return (
    <ReactRouter history={browserHistory}>
      <Layout>
        <CustomSwitch>
          <Route exact path={RouterUrl.Homepage}>
            <Helmet>
              <title>
                {intl.formatMessage(
                  {
                    id: "seo.title",
                    defaultMessage: "{0} | Talentis client portal"
                  },
                  {
                    0: intl.formatMessage(
                      {
                        id: "seo.dashboard",
                        defaultMessage: "Dashboard | Search Firm"
                      },
                      { 0: user?.searchFirmName }
                    )
                  }
                )}
              </title>
            </Helmet>
            <ListAssignment />
          </Route>
          <Route exact path={RouterUrl.AssignmentDetailPage}>
            <ClientPortalConfigurationWrapper />
          </Route>
          <Route path={RouterUrl.OIDCRedirectRouteUrl}>
            <Redirect to="/" />
          </Route>
          <Route exact path={RouterUrl.Test}>
            <Test />
          </Route>
        </CustomSwitch>
      </Layout>
    </ReactRouter>
  )
}
