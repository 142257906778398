import { useCallback } from "react"
import { useAuth } from "oidc-react"
import { useIntl } from "react-intl"
import { Button, Flex, H1, Link, Tag } from "@ikiru/talentis-fpc"

import { ConfirmationModal } from "components/modals/ConfirmationModal"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"

export const Test: React.FC = () => {
  const intl = useIntl()
  const auth = useAuth()
  const { open, close } = useModal()

  const openCustomModal = useCallback(() => {
    open(
      ModalNames.Confirmation,
      <ConfirmationModal
        title="Title"
        subtitle="Subtitle"
        cancelButtonLabel="cancel"
        confirmButtonLabel="confirm"
        onClose={() => close(ModalNames.Confirmation)}
        onCancel={() => close(ModalNames.Confirmation)}
        onConfirm={() => close(ModalNames.Confirmation)}
      />
    )
  }, [open, close])

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <H1>
        {intl.formatMessage({
          id: "misc.helloWorld",
          defaultMessage: "Hello worlds"
        })}
      </H1>
      <Button onClick={openCustomModal}>Open modal</Button>
      <Tag>Tag</Tag>
      <Link>Link</Link>
      <strong>Logged in! 🎉</strong>
      <Button onClick={() => auth.signOutRedirect()}>Logout</Button>
    </Flex>
  )
}
