import { useCallback, useEffect, useState } from "react"
import { Flex, H1, Loader, LargeText } from "@ikiru/talentis-fpc"
import { useIntl } from "react-intl"
import { Assignment } from "./assignment.types"
import { apiRequest } from "setup/api/api"
import { AssignmentsEndpoints } from "setup/api/endpoints/endpoints"
import Assignments from "./components/Assignment"
import React from "react"

export const ListAssignment = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [assignments, setAssignments] = useState<Assignment[]>([])
  const intl = useIntl()

  const fetchAssignments = useCallback(async () => {
    setIsLoading(true)
    const [, response] = await apiRequest.get({
      endpoint: AssignmentsEndpoints.SharedAssignments
    })
    setAssignments(response?.data?.sharedAssignmentDetails)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    fetchAssignments()
  }, [fetchAssignments])

  return (
    <>
      <H1 color="green.standard" my="none" mr="xxs" mb="xs">
        {intl.formatMessage({
          id: "messages.assignment.plural",
          defaultMessage: "Assignments"
        })}
      </H1>

      <Flex flexDirection="column" bg="grey.lightest" p="m">
        {isLoading ? (
          <Loader />
        ) : assignments.length ? (
          <Assignments assignments={assignments} />
        ) : (
          <LargeText color="grey.standard" px="xs">
            {intl.formatMessage({
              id: "messages.assignment.noAssignment",
              defaultMessage: "messages.assignment.noAssignment"
            })}
          </LargeText>
        )}
      </Flex>
    </>
  )
}
